import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, Translate } from 'utils/api';
import { Notify } from 'utils/notify';
import { reloadTranslations } from 'utils/translate-util';

interface UITranslate extends Translate {
  deleted: boolean;
}

@autoinject
export class AdminTranslationsList {
  private defaults: { [key: string]: string } = {"deleted" : " "};
  private excelDownloadUrl: string = this.api.translateExcelDownloadUrl();
  private excelFiles?: FileList;
  private fieldList: FieldSpec[] = [

    { key: "key", header: "common.key", type: "text" },
    { key: "fiDefault", header: "translations.fiDefault", type: "text", },
    { key: "fiValue", header: "translations.fiValue", type: "text", },
    { key: "enDefault", header: "translations.enDefault", type: "text", },
    { key: "enValue", header: "translations.enValue", type: "text", },
    { key: "comment", header: "translations.comment", type: "text", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];
  private translateList: UITranslate[] = [];

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private bindingSignaler: BindingSignaler, private notify: Notify) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    let translateList = await this.api.publicTranslateList({all: true});
    this.translateList = translateList.map(x => {
      return {
        ...x, deleted: !!x.deleteTime
      }
    });
  }
  async uploadExcelFile() {
    if (!this.excelFiles) {
      return;
    }

    let reader = new FileReader();
    reader.addEventListener("load", async () => {
      if (typeof reader.result === "string") {
        let file = reader.result.replace(/^[^,]+,/, "");
        try {
          let translations = await this.api.translateExcelUpdate({
            excel: file,
          });
          reloadTranslations(this.bindingSignaler, this.i18n, translations);
          await this.refresh();
          this.notify.info("common.updateDone");
          this.emptyFileInput();
        } catch (error) {
          this.emptyFileInput();
          throw error;
        }
      }
    });
    reader.readAsDataURL(this.excelFiles[0]);
  }

  rowCall(key: string, row: UITranslate) {
    this.router.navigateToRoute('admin/translations/edit', {key: row.key});
  }

  emptyFileInput() {
    document.querySelectorAll("#excelFiles").forEach(x => (x as HTMLInputElement).value = "");
  }
}
